<template>
  <div class="checkpoint">
    <svg id="svg" xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
      <rect class="rect" :width="width + margin * 2" :height="height + header.height + margin * 2"></rect>
      <g :transform="`translate(${margin},${margin})`">
        <g :transform="`translate(0,0)`" id="lanes">
          <rect class="rect" :width="width" :height="height + header.height"></rect>
          <!--Lanes-->
          <g class="lane" :transform="`translate(${width/lanes_len * (ln.lane_num-1)},0)`"
             v-for="ln in checkpoint.lanes"
             :key="ln.lane_num">
            <!--Header-->
            <!--            <g id="header" :transform="`translate(0,${header.top ? 0 : height})`">-->
            <!--              <rect class="rect" x="0" y="0" :width="width/lanes_len" :height="header.height"></rect>-->
            <!--              <g class="header_kpi" :transform="`translate(${lane_shift(ln.lane_num)},0)`">-->
            <!--                <text :y="header.height/3 - header.height/10" :x="width/lanes_len/2" text-anchor="middle">lane-->
            <!--                  {{ ln.lane_num }}-->
            <!--                </text>-->
            <!--                <text :y="header.height/3 * 2 - header.height/10" :x="width/lanes_len/2" text-anchor="middle">-->
            <!--                  {{ ln.areas.screening[bucket].passengers }}-->
            <!--                </text>-->
            <!--                <text :y="header.height - header.height/10" :x="width/lanes_len/2" text-anchor="middle">-->
            <!--                  {{ `PAX ${minutes} min` }}-->
            <!--                </text>-->
            <!--              </g>-->
            <!--            </g>-->
            <!--Areas-->
            <g :transform="`translate(0,${header.top ? header.height : 0})`" id="areas">
              <rect class="rect" x="0" y="0" :width="width/lanes_len" :height="height"></rect>
              <g class="area"
                 :transform="`translate(${position_width * 2 +  lane_shift(ln.lane_num)},${position_height * (area.index - 1)})`"
                 v-for="area in lane.areas" :key="area.index">
                <!--process name-->
                <g v-if="ln.lane_num % 2 === 0">
                  <line class="process_divider" :x1="-4 * position_width - lane_shift(ln.lane_num)"
                        :x2="- lane_shift(ln.lane_num)"></line>
                  <text class="process_name" :x="-2 * position_width - lane_shift(ln.lane_num)"
                        :y="(position_height * area.len - position_border)/2 + 7"
                        text-anchor="middle">{{ area.name.toUpperCase() }}
                  </text>
                </g>
                <!--area-->
                <area-pos v-if="!show_positions && areas.length > 0"
                          x="0"
                          y="0"
                          :width="position_width.toString()"
                          :height="(position_height * area.len - position_border).toString()"
                          :points="divest_points(area.len, ln.lane_num, 1, 1)"
                          :process_step="area.name"
                          :id="`lane-${ln.lane_id}-${area.name}`"
                          :show_positions="show_positions"
                          :show_data="show_data"
                          :minutes_prop=minutes
                          :lane_id="ln.lane_id"
                          :area_num="area.index"
                          position_id="position_id"
                          area_id_string="area_id_string"
                />
                <!-- Positions -->
                <g v-if="show_positions && positions.length > 0">
                  <g :transform="`translate(0,${position_height * (pos_index - 1)})`" v-for="pos_index in area.len"
                     :key="pos_index">
                    <area-pos
                      x="0"
                      y="0"
                      :width="position_width.toString()"
                      :height="(position_height - position_border).toString()"
                      :points="divest_points(1, ln.lane_num, pos_index, area.len)"
                      :process_step="area.name"
                      :id="`lane-${ln.lane_id}-${area.name}-${pos_index}`"
                      :show_positions="show_positions"
                      :show_data="show_data"
                      :minutes_prop=minutes
                      :lane_id="ln.lane_id"
                      :area_num="area.index"
                      position_id="position_id"
                      area_id_string="area_id_string"
                      :show_second_metric="true"
                      :lane_orientation="ln.lane_num % 2 === 0 ? 'left' : 'right'"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import AreaPos from '@/components/AreaPos'
import { mapGetters } from 'vuex'
import api from '@/lib/api'

export default {
  name: 'Checkpoint',
  components: {
    AreaPos
  },
  props: {
    measure: String,
    func: String,
    filters_local: Object,
    filters_global: Object
  },
  data () {
    return {
      show_filter: false,
      height: 500,
      width: 1000,
      header: {
        height: 0,
        top: true
      },
      // positions_len: 12,
      position_border: 3,
      lanes_offset: 4,
      lanes_len: 4,
      lanes: 4,
      lane: {
        areas: [
          {
            name: 'recheck',
            index: 1,
            len: 1
          },
          {
            name: 'reclaim',
            index: 2,
            len: 4
          },
          {
            name: 'screening',
            index: 6,
            len: 1
          },
          {
            name: 'divest',
            index: 7,
            len: 3
          }
        ]
      },
      show_positions: true,
      show_data: true,
      minutes: 60
    }
  },
  computed: {
    filters () {
      return this.show_filter ? this.filters_local : this.filters_global
    },
    bucket () {
      return `bucket_${this.minutes}_minutes`
    },
    margin () {
      return this.width / 100
    },
    viewBox () {
      return `0 0 ${this.width + this.margin * 2} ${this.height + this.header.height + this.margin * 2}`
    },
    position_height () {
      return this.height / this.positions_len
    },
    position_width () {
      return this.width / this.lanes_len / 5
    },
    positions_len () {
      return this.lane.areas.reduce(function (a, b) {
        return a + b.len
      }, 0)
    },
    lane_comp () {
      const lane = {
        // num: num,
        // positions_len: this.positions_len,
        measure_name: 'occupancy',
        measure_value: '75%',
        areas: [
          {
            name: 'recheck',
            index: 1,
            len: 1
          },
          {
            name: 'reclaim',
            index: 2,
            len: 4
          },
          {
            name: 'screening',
            index: 6,
            len: 1
          },
          {
            name: 'divest',
            index: 7,
            len: 3
          }
        ]
      }
      return lane
    },
    ...mapGetters(['cpNum', 'checkpoint', 'tenant', 'positions', 'areas'])
    // lane_comp () {
    //   const lane = this.$store.state.lane.filter(item => item.v0 < 25 & item.v0 > 10)
    //   lane.sort((a, b) => a[this.sortField].toString().localeCompare(b[this.sortField].toString()))
    //   return lane
    //   // return this.$store.state.lane
    //   // const newlane = lane.map((l) => ({ ...l, id: uniqueId() }));
    //   // console.log("lane data:", lane);
    // }
  },
  methods: {
    getDataBQ () {
      const query = {
        measure_type: this.measure,
        category: this.show_positions ? 'position' : 'process',
        func: this.func,
        tenant: this.tenant.airport_code,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to
      }
      api.get('measurements/checkpoint', query)
        .then(data => {
          // console.log(data)
          // this.chart_data = data
          // this.updateChart()
          if (this.show_positions) {
            this.$store.commit('setPositions', data)
          } else {
            this.$store.commit('setAreas', data)
          }
        })
        .catch((error) => {
          console.log(error)
          // alert(error.message)
        })
    },
    divest_points (height, laneIndex, num, outOf) {
      const dir = laneIndex % 2 === 0 ? -1 : 1
      const shiftTop = dir * this.position_width * 2 / outOf * (num - 1)
      const shiftBottom = dir * this.position_width * 2 / outOf * num
      const topLeft = `${shiftTop} 0`
      const topRight = `${this.position_width * 1 + shiftTop} 0`
      const bottomRight = `${this.position_width * 1 + shiftBottom} ${this.position_height * height - this.position_border}`
      const bottomLeft = `${shiftBottom} ${this.position_height * height - this.position_border}`
      return `${topLeft} ${topRight} ${bottomRight} ${bottomLeft} ${topLeft}`
    },
    lane_shift (laneIndex) {
      return laneIndex % 2 === 0 ? this.position_width / 2 : -1 * this.position_width / 2
    },
    bindItems () {
      if (this.show_positions) {
        this.$store.dispatch('bindPositions')
      } else {
        this.$store.dispatch('bindAreas')
      }
    },
    unbindItems () {
      this.$store.dispatch('unbindAreas')
      this.$store.dispatch('unbindPositions')
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
    this.getDataBQ()
  },
  beforeDestroy () {
    // this.unbindItems()
  },
  watch: {
    filters: {
      handler (newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.getDataBQ()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkpoint {
  align-content: center;
  align-items: center;
}

#svg {
  align-self: center;
  /*stroke-width: 2;*/
  /*stroke: #2D1D60;*/
}

/* #header {
}

#lanes {
} */

.rect {
  fill: none;
  /*stroke: #323232;*/
  /* stroke-width: 1; */
}

.header_kpi text {
  /* stroke: #2D1D60;
  stroke-width: 0; */
  fill: #2D1D60;
}

.area {
  fill: none;
  stroke-width: 1;
  /* stroke: #323232; */
}

.process_divider {
  stroke-width: 1;
  stroke: #2D1D60;
  opacity: .25;
}

.process_name {
  /* stroke-width: 2; */
  fill: #2D1D60;
  font-size: 14px;
  /* font-weight: bold; */
  /* opacity: .5; */
}
</style>
