<template>
  <div>
    <h1 class="chart-title">{{ title }}
      <svg class="icon" viewBox="0 0 70 70"
           @click="show_filter=!show_filter"
           @mouseover="hover = true"
           @mouseleave="hover = false">
        <circle class="icon__circle" :class="{'icon__circle--hover': hover, 'icon__circle--on': show_filter}" cx="35.18"
                cy="34.98" r="32"/>
        <g class="icon__line" :class="{'icon__line--hover': hover, 'icon__line--on': show_filter}">
          <line x1="19.09" y1="24.16" x2="51.26" y2="24.16"/>
          <line x1="23.63" y1="38.07" x2="46.73" y2="38.07"/>
          <line x1="28.27" y1="51.04" x2="42.09" y2="51.04"/>
        </g>
      </svg>
    </h1>
    <div class="filters" v-show="show_filter">
      <div class="selector">
        <label for="category">Split by:</label>
        <select v-model="category" id="category" style="width: 65px;">
          <option disabled value="">Please select the category</option>
          <option value="lane">lane</option>
          <option value="process">area</option>
        </select>
      </div>
      <div class="selector">
        <label for="start">Start date:</label>
        <input v-model="filters.date_from" type="date" id="start" name="start"
               min="2022-02-01">
      </div>
      <div class="selector">
        <label for="end">End date:</label>
        <input v-model="filters.date_to" type="date" id="end" name="end"
               min="2022-02-01">
      </div>
    </div>
    <div class="chart">
      <canvas id="myChart" ref="myChart" :width="width" :height="height"></canvas>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js'
import 'chartjs-adapter-moment'
// import { checkpointRef } from '@/plugins/firebaseInit'
import { mapGetters } from 'vuex'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

export default {
  name: 'ChartComponent',
  props: {
    width: String,
    height: String,
    chart_type: String,
    category: String,
    stacked: Boolean,
    fill: Boolean,
    label: String,
    measure: String,
    func: String,
    filters_global: Object
  },
  data () {
    return {
      show_filter: false,
      filters_local: {},
      hover: false,
      chart_data: [],
      chart_obj: null,
      api_result: null,
      datasets: [],
      dataset: {
        name: '',
        color: '',
        labels: [],
        data: []
      },
      // chart_type: 'line',
      // stacked: true,
      // label: 'day', // 'dow', 'hour'
      // measure: 'passengers', // 'occupancy', 'avg_dwell_time'
      // filters: {
      // category: 'lane', // 'process'
      //   lane: '',
      //   process: '',
      //   date_from: '2022-02-01',
      //   date_to: '2022-03-07',
      //   limit: 20
      // },
      // lanes: [1, 2, 3, 4],
      process_steps: [
        'divest',
        // 'screening',
        'reclaim',
        'recheck'
      ],
      divest_positions: [
        'divest-1',
        'divest-2',
        'divest-3',
        'divest-4',
        'divest-5'
      ],
      divest_colors: {
        'divest-1': '#103762',
        'divest-2': '#123c6b',
        'divest-3': '#174E8B',
        'divest-4': '#1b5ca4',
        'divest-5': '#2276d3'
      },
      lane_colors: {
        1: '#174E8B',
        2: '#EFA08D',
        3: '#4ADFAF',
        4: '#2D1D6080'
      },
      process_colors: {
        divest: '#174E8B',
        screening: '#2d1d60',
        recheck: '#EFA08D',
        reclaim: '#4ADFAF'
      },
      measures: {
        passengers: 'Number of Passengers',
        occupancy: 'Occupancy',
        avg_dwell_time: 'Dwell Time'
      },
      funcs: {
        min: 'Minimum',
        max: 'Maximum',
        sum: 'Total',
        avg: 'Average'
      }
    }
  },
  computed: {
    dates () {
      // return this.getDaysArray(new Date(this.filters.date_from), new Date(this.filters.date_to))
      return this.getDaysArray(this.filters.date_from, this.filters.date_to)
    },
    filters () {
      return this.show_filter ? this.filters_local : this.filters_global
    },
    title () {
      return `${this.funcs[this.func]} ${this.measures[this.measure]}`
    },
    ...mapGetters(['cpNum', 'tenant', 'checkpoint'])
  },
  methods: {
    getData () {
      this.getDataBQ()
    },
    formatFirestoreData (fsData) {
      this.chart_data.bucket_end_time = fsData.map((row) => this.timeString(row.bucket_end_time))
      this.chart_data.passengers = fsData.map((row) => row.passengers)
    },
    getDaysArray (start, end) {
      // const options = {
      //   year: '4-digit',
      //   month: '2-digit',
      //   day: '2-digit'
      //   // second: '2-digit'
      // }
      end = new Date(end)
      const arr = []
      // eslint-disable-next-line no-unmodified-loop-condition
      for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        // arr.push(dt.toISOString().slice(0, 10))
        arr.push(new Date(dt))
      }
      return arr
    },
    genColor () {
      const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
      let hex = '#'

      for (let i = 0; i < 6; i++) {
        const index = Math.floor(Math.random() * hexValues.length)
        hex += hexValues[index]
      }
      return hex
    },
    getDataBQ () {
      const requests = []
      this.chart_data = []
      if (this.category === 'lane') {
        for (const lane of this.checkpoint.lanes) {
          const query = {
            category: this.category,
            measure_type: this.measure,
            func: this.func,
            label: this.label,
            tenant: this.tenant.airport_code,
            lane: lane.lane_id,
            // process_step: this.filters.process,
            date_from: this.filters.date_from,
            date_to: this.filters.date_to,
            limit: this.filters.limit
          }
          const promise = api.get(`measurements/chart/${this.measure}`, query)
            .then(data => {
              // console.log(data)
              // this.chart_data = data
              // this.updateChart()
              return {
                category: lane.lane_num,
                ...data
              }
            })
            .catch((error) => {
              console.log(error)
              // alert(error.message)
            })
          requests.push(promise)
        }
      } else if (this.category === 'process') {
        for (const process of this.process_steps) {
          const query = {
            category: this.category,
            measure_type: this.measure,
            func: this.func,
            label: this.label,
            tenant: this.tenant.airport_code,
            // lane: lane.lane_id,
            process_step: process,
            date_from: this.filters.date_from,
            date_to: this.filters.date_to,
            limit: this.filters.limit
          }
          const promise = api.get(`measurements/chart/${this.measure}`, query)
            .then(data => {
              // console.log(data)
              // this.chart_data = data
              // this.updateChart()
              if (data) {
                return {
                  category: process,
                  ...data
                }
              }
            })
            .catch((error) => {
              console.log(error)
              alert(error.message)
            })
          requests.push(promise)
        }
      } else if (this.category === 'date') {
        for (const date of this.dates) {
          // console.log(date)
          const dateFrom = date.toISOString().slice(0, 10)
          let dateTo = new Date(dateFrom)
          dateTo.setDate(dateTo.getDate() + 1)
          dateTo = dateTo.toISOString().slice(0, 10)
          const query = {
            category: this.category,
            measure_type: this.measure,
            func: this.func,
            label: this.label,
            tenant: this.tenant.airport_code,
            // lane: lane.lane_id,
            // process_step: this.filters.process,
            date_from: dateFrom,
            date_to: dateTo,
            limit: this.filters.limit
          }
          const promise = api.get(`measurements/chart/${this.measure}`, query)
            .then(data => {
              // console.log(data)
              // this.chart_data = data
              // this.updateChart()
              if (data) {
                return {
                  category: dateFrom,
                  ...data
                }
              }
            })
            .catch((error) => {
              console.log(error)
              alert(error.message)
            })
          requests.push(promise)
        }
      } else if (this.category === 'position') {
        for (const position of this.divest_positions) {
          const query = {
            category: this.category,
            measure_type: this.measure,
            func: this.func,
            label: this.label,
            tenant: this.tenant.airport_code,
            // lane: lane.lane_id,
            process_step: 'divest',
            position: position,
            date_from: this.filters.date_from,
            date_to: this.filters.date_to,
            limit: this.filters.limit
          }
          const promise = api.get(`measurements/chart/${this.measure}`, query)
            .then(data => {
              // console.log(data)
              // this.chart_data = data
              // this.updateChart()
              if (data) {
                return {
                  category: position,
                  ...data
                }
              }
            })
            .catch((error) => {
              console.log(error)
              alert(error.message)
            })
          requests.push(promise)
        }
      }
      Promise.all(requests)
        .then(data => {
          console.log('Resolved BQ data from all promises')
          this.chart_data = data
          this.updateChart()
        })
    },
    updateChart () {
      this.chart_obj.data.datasets = []
      if (this.category === 'lane') {
        for (const catData of this.chart_data) {
          if (catData && catData.label.length > 0) {
            this.chart_obj.data.labels = catData.label.reverse()
            this.chart_obj.data.datasets.push(
              {
                label: `Lane ${catData.category}`,
                data: catData.value.reverse(),
                borderColor: this.lane_colors[catData.category.toString()],
                fill: this.fill,
                backgroundColor: this.lane_colors[catData.category.toString()],
                cubicInterpolationMode: 'monotone',
                tension: 0.4
              }
            )
          }
        }
      } else if (this.category === 'process') {
        for (const catData of this.chart_data) {
          if (catData && catData.label.length > 0) {
            this.chart_obj.data.labels = catData.label.reverse()
            this.chart_obj.data.datasets.push(
              {
                label: catData.category,
                data: catData.value.reverse(),
                borderColor: this.process_colors[catData.category],
                borderDash: this.measure === 'avg_dwell_time' ? [5, 5] : [0, 0],
                fill: this.fill,
                backgroundColor: this.process_colors[catData.category],
                cubicInterpolationMode: 'monotone',
                tension: 0.4
              }
            )
          }
        }
      } else if (this.category === 'date') {
        for (const catData of this.chart_data) {
          if (catData && catData.label.length > 0) {
            const color = this.genColor()
            this.chart_obj.data.labels = catData.label.reverse()
            this.chart_obj.data.datasets.push(
              {
                label: catData.category,
                data: catData.value.reverse(),
                // borderColor: this.process_colors[catData.category],
                borderColor: color,
                fill: this.fill,
                // backgroundColor: this.process_colors[catData.category],
                backgroundColor: color,
                cubicInterpolationMode: 'monotone',
                tension: 0.4
              }
            )
          }
        }
      } else if (this.category === 'position') {
        for (const catData of this.chart_data) {
          if (catData && catData.label.length > 0) {
            // const color = this.genColor()
            this.chart_obj.data.labels = catData.label.reverse()
            this.chart_obj.data.datasets.push(
              {
                label: catData.category,
                data: catData.value.reverse(),
                borderDash: this.measure === 'avg_dwell_time' ? [5, 5] : [0, 0],
                borderColor: this.divest_colors[catData.category],
                fill: this.fill,
                backgroundColor: this.divest_colors[catData.category],
                cubicInterpolationMode: 'monotone',
                tension: 0.4
              }
            )
          }
        }
      }
      this.chart_obj.update()
    },
    timeString (ts) {
      const options = {
        hour: 'numeric',
        minute: '2-digit'
        // second: '2-digit'
      }
      const date = ts.toDate()
      return date.toLocaleTimeString('nl-NL', options)
    }
    // lane_color (key) {
    //   let color
    //   switch (key) {
    //     case 1:
    //       color = '#174E8B'
    //       break
    //     case 2:
    //       color = '#EFA08D'
    //       break
    //     case 3:
    //       color = '#4ADFAF'
    //       break
    //     case 4:
    //       color = '#2D1D6080'
    //       break
    //   }
    //   return color
    // },
    // process_color (key) {
    //   let color
    //   switch (key) {
    //     case 'divest':
    //       color = '#174E8B'
    //       break
    //     case 'screening':
    //       color = '#2d1d60'
    //       break
    //     case 'recheck':
    //       color = '#EFA08D'
    //       break
    //     case 'reclaim':
    //       color = '#4ADFAF'
    //       break
    //   }
    //   return color
    // }
  },
  created () {
    // this.$store.dispatch('bindPAXScreening', this.lane_id)
    this.filters_local = this.filters_global
  },
  mounted () {
    // const ctx = document.getElementById('myChart')
    const measure = this.measure
    const ctx = this.$refs.myChart
    this.chart_obj = new Chart(ctx, {
      type: this.chart_type,
      data: {
        labels: [],
        datasets: []
      },
      options: {
        scaleShowValues: true,
        scales: {
          x: {
            type: this.label === 'datetime' ? 'time' : 'linear',
            min: this.label === 'datetime' ? null : 0,
            max: this.label === 'datetime' ? null : 23,
            display: true,
            stacked: this.stacked,
            title: {
              display: false,
              text: 'Date'
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              major: {
                enabled: true
              },
              // color: function(context) {
              //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
              // },
              font: function (context) {
                if (context.tick && context.tick.major) {
                  return {
                    weight: 'bold'
                  }
                }
              }
            }
          },
          y: {
            display: true,
            stacked: this.stacked,
            title: {
              display: true,
              text: this.measures[this.measure],
              color: '#807e8b',
              font: {
                // family: 'Times',
                size: 12,
                style: 'normal',
                lineHeight: 1.2
              },
              padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 5
              }
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback (value, index, ticks) {
                if (measure === 'occupancy') {
                  return (value * 100).toFixed() + '%'
                } else if (measure === 'avg_dwell_time') {
                  return value + 's'
                } else {
                  return value
                }
              }
            }
          }
        },
        animation: {
          duration: 200
        },
        elements: {
          point: {
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        // resizeDelay: 500,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false,
            text: this.title,
            position: 'top',
            align: 'start',
            color: '#2D1D60',
            font: {
              // family: 'Times',
              size: 16,
              style: 'normal',
              lineHeight: 1.2
            }
          }
        }
      }
    })
    // initializes data for first time
    this.getData()
  },
  beforeUpdate () {
  },
  beforeDestroy () {
    this.chart_obj.destroy()
    // this.$store.dispatch('unbindPAXScreening')
  },
  watch: {
    filters: {
      handler (newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.getData()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  position: relative;
  height: 90%;
  width: 100%;
  margin: 0 auto;
}

.chart-title {
  margin-bottom: 0px;
  color: #4ADFAF;
  font-size: 20px;
  text-align: center;
}

canvas {
  margin: 0;
}

.filters {
  margin: 10px;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector {
  margin-right: 10px;
  display: inline;
  align-content: center;
}

label {
  /*margin-right: 5px;*/
}

.icon {
  height: 21px;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-left: 6px;
  fill: none;
  stroke: #2d1d60;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.icon__circle {
  stroke-width: 5px;
}

.icon__circle--hover {
  fill: #2d1d60;
}

.icon__circle--on {
  stroke: #4ADFAF;
  fill: #2d1d60;
}

.icon__line {
  stroke-width: 6px;
}

.icon__line--hover {
  stroke: #fff;
}

.icon__line--on {
  stroke: #4ADFAF;
}

</style>
