<template>
  <div class="analysis-view">
    <!--Filters-->
    <div class="sticky">
      <div class="filters">
        <!--      <div class="selector">-->
        <!--        <label>Lane</label>-->
        <!--        <select>-->
        <!--          <option disabled value="">Please select a lane number</option>-->
        <!--          <option value=1>1</option>-->
        <!--          <option value=2>2</option>-->
        <!--          <option value=3>3</option>-->
        <!--          <option value=4>4</option>-->
        <!--        </select>-->
        <!--      </div>-->
        <!--      <div class="selector">-->
        <!--        <label>Area</label>-->
        <!--        <select>-->
        <!--          <option disabled value="">Please select a lane area</option>-->
        <!--          <option value="divest">Divest</option>-->
        <!--          <option value="reclaim">Reclaim</option>-->
        <!--          <option value="recheck">Recheck</option>-->
        <!--        </select>-->
        <!--      </div>-->
        <div class="selector">
          <label for="layout">Columns:</label>
          <select v-model="layout" id="layout" style="width: 65px;">
            <option disabled value="">Columns to display</option>
            <option value="1">x1</option>
            <option value="2">x2</option>
            <option value="3">x3</option>
          </select>
        </div>
<!--        <div class="selector">-->
<!--          <label for="category">Split by:</label>-->
<!--          <select v-model="filters.category" id="category" style="width: 65px;">-->
<!--            <option disabled value="">Please select the category</option>-->
<!--            <option value="lane">lane</option>-->
<!--            <option value="process">area</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="selector">
          <label for="start">Start date:</label>
          <input v-model="filters.date_from" type="date" id="start" name="start"
                 min="2022-02-01">
        </div>
        <div class="selector">
          <label for="end">End date:</label>
          <input v-model="filters.date_to" type="date" id="end" name="end"
                 min="2022-02-01">
        </div>
        <!--      <div class="selector">-->
        <!--        <label for="limit">Limit</label>-->
        <!--        <select v-model="filters.limit" id="limit" style="width: 65px;">-->
        <!--          <option disabled value="">Please select number of rows to show</option>-->
        <!--          <option value=20>20</option>-->
        <!--          <option value=50>50</option>-->
        <!--          <option value=100>100</option>-->
        <!--          <option value=500>500</option>-->
        <!--        </select>-->
        <!--      </div>-->
      </div>
      <hr>
    </div>
    <!--Charts-->
    <h1 class="section-title">CHECKPOINT OVERVIEW</h1>
    <div class="kpi-area">
      <!--      <Kpi :value="null" label="Checkpoint Capacity Score"/>-->
      <Kpi measure="passengers" func="avg" :filters_global="filters" label="Avg. PAX Screening Throughput (hour)"/>
      <Kpi measure="passengers" func="max" :filters_global="filters" label="Max. PAX Screening Throughput (hour)"/>
      <Kpi measure="occupancy" func="avg" :filters_global="filters" format="percent"
           label="Avg. Divest Occupancy (sig. usage)"/>
      <Kpi measure="avg_dwell_time" func="avg" :filters_global="filters" label="Avg. Divest Dwell Time (sec.)"/>
    </div>
    <!--Checkpoint-->
    <div class="map-area">
      <check-point
        measure="passengers"
        func="avg"
        :filters_local="filters"
        :filters_global="filters"
      />
    </div>
    <hr>
    <!--Charts-->
    <div class="grid-main" ref="charts">
      <chart class="graph" v-bind:style="graphStyle" v-for="ch in charts" :key="ch.num"
             :width="chart_width"
             :height="chart_height"
             :chart_type="ch.chart_type"
             :category="ch.category"
             :stacked="ch.stacked"
             :fill="ch.fill"
             :label="ch.label"
             :measure="ch.measure"
             :func="ch.func"
             :filters_global="filters"
      />
    </div>
  </div>
</template>

<script>
// import api from '@/lib/api'
import { mapGetters } from 'vuex'
import Kpi from '@/components/KPIAnalysis'
import cpComponent from '@/components/CheckPointAnalysis'
import Chart from '@/components/ChartAnalysisView'
// import ChartComponent from '@/components/Chart'
// import lineChartPassengers from '@/components/LineChartPassengers'
// import uniqueId from 'lodash.uniqueid';

export default {
  name: 'AnalysisView',
  components: {
    Chart,
    Kpi,
    CheckPoint: cpComponent
  },
  data () {
    return {
      layout: 1,
      chart_width: '1000',
      chart_height: '500',
      graphStyle: {
        height: '40vh',
        width: '100%'
      },
      lanes: [
        1, 2, 3, 4
      ],
      process_steps: [
        'divest',
        // 'screening',
        'reclaim',
        'recheck'
      ],
      filters: {
        category: 'lane',
        lane: '',
        process: '',
        date_from: this.$store.getters.dateFrom,
        date_to: this.$store.getters.dateTo,
        limit: 500
      },
      charts_template: {
        title: 'Custom',
        y_title: 'Number of Passengers',
        chart_type: 'bar',
        stacked: true,
        label: 'day', // 'dow', 'hour'
        measure: 'passengers', // 'occupancy', 'avg_dwell_time'
        filters: {
          category: 'lane',
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      }
    }
  },
  computed: {
    ...mapGetters(['cpNum', 'charts'])
  },
  methods: {},
  beforeCreate () {
    // `this` points to the vm instance
    // this.$store.dispatch('bindCheckpoint')
  },
  created () {
  },
  watch: {
    layout: function () {
      if (this.layout === '3') {
        this.graphStyle.height = '300px'
        this.graphStyle.width = '100%'
      } else if (this.layout === '2') {
        this.graphStyle.height = '500px'
        this.graphStyle.width = '100%'
      } else {
        this.graphStyle.height = '500px'
        this.graphStyle.width = '80%'
      }
      this.$refs.charts.style.setProperty('--grid-items', this.layout)
    }
  }
}

</script>

<style scoped>
.analysis-view {
  width: 100%;
  margin: 0 auto;
}

.kpi-area {
  display: flex;
  margin: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.map-area {
  /*height: 90%;*/
  /*padding: 15px;*/
  /*margin: 10px 0;*/
  /*width: 70%;*/
  /*background-color:#c4c4c4;*/
  display: inline-block;
}

/*:root {*/
/*  --grid-gap: 30px;*/
/*  --grid-min: 175px;*/
/*  --grid-items: auto-fill;*/
/*}*/

.grid-main {
  --grid-gap: 30px;
  --grid-min: 175px;
  --grid-items: 1;
  display: grid;
  grid-template-columns: repeat(var(--grid-items), minmax(var(--grid-min), 1fr));
  grid-gap: var(--grid-gap);
  counter-reset: rank;
  margin: 4%;
}

.graph {
  height: 500px;
  width: 80%;
  margin: 0 auto;
}

hr {
  margin-top: 0px;
  border-top: 2px solid #4ADFAF;
}

.section-title {
  margin-bottom: 0px;
  color: #4ADFAF;
  font-size: 20px;
  text-align: center;
}

.header h1 {
  font-size: 28px;
  color: #2D1D60;
  margin: 0px;
}

.header p {
  font-size: 15px;
  color: #505050;
  margin: 0px;
}

.filters {
  margin: 10px;
  display: inline-block;
  align-content: center;
}

div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
}

.selector {
  margin-right: 10px;
  display: inline;
  align-content: center;
}

label {
  /*margin-right: 5px;*/
}

</style>
