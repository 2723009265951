<template>
  <div class="kpi">
    <!-- reference point -->
    <Kpi
      :value="value"
      :format="format"
      :header="header"
      :label="label"
    />
  </div>
</template>

<script>
import Kpi from '@/components/KPI'
import api from '@/lib/api'
import { mapGetters } from 'vuex'

export default {
  name: 'KpiA',
  components: {
    Kpi
  },
  props: {
    measure: String,
    func: String,
    filters_global: Object,
    format: String,
    header: String,
    label: String
  },
  data () {
    return {
      value: null
    }
  },
  computed: {
    ...mapGetters(['tenant'])
  },
  methods: {
    getData () {
      const query = {
        // category: this.filters.category,
        measure_type: this.measure,
        func: this.func,
        tenant: this.tenant.airport_code,
        date_from: this.filters_global.date_from,
        date_to: this.filters_global.date_to
      }
      api.get('measurements/kpi', query)
        .then(data => {
          this.value = data ? data[0] : 0
        })
        .catch((error) => {
          alert(error.message)
        })
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    filters_global: {
      handler (newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.getData()
      },
      deep: true
    }
  }
}
</script>
