<template>
  <div class="kpi">
    <p>{{ header }}</p>
    <h1>{{ get_formatted_value }}</h1>
    <p>{{ label }}</p>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'Kpi',
  props: {
    header: String,
    value: Number,
    format: String,
    label: String
  },
  data () {
    return {
      tweenedNumber: null
    }
  },
  computed: {
    get_formatted_value () {
      if (this.value === '-' || this.value === null) {
        return '-'
      } else {
        return new Intl.NumberFormat('en-US', {
          style: this.format,
          maximumFractionDigits: 0
        }).format(parseFloat(this.tweenedNumber))
      }
    }
  },
  mounted () {
    this.tweenedNumber = this.value
  },
  watch: {
    value (newValue) {
      if (newValue) {
        gsap.to(this.$data, {
          duration: 0.5,
          tweenedNumber: newValue
        })
      }
    }
  }
}
</script>

<style scoped>
.kpi {
  display: inline-block;
  text-align: center;
  padding: 10px;
  margin: 10px;
  height: 100%;
}

.kpi h1 {
  margin: 0px;
  font-size: 32px;
}

.kpi p {
  margin: 0px;
  font-size: 12px;
}
</style>
