<template>
  <!--  <g-->
  <!--    @mouseover="hover = true"-->
  <!--    @mouseleave="hover = false"-->
  <!--  >-->
  <g>
    <!--    <g v-if="!item.realtime && last_bucket"-->
    <!--       @mouseover="hover = true"-->
    <!--       @mouseleave="hover = false"-->
    <!--    >-->
    <polygon v-if="process_step === 'divest'" :id="id" class="cls-1" :points="points"
             :style="styleComputedDivest"
    />
    <rect v-else-if="process_step === 'screening'" :id="id" class="cls-1" :x="x" :y="y" :width="width" :height="height"
          :style="styleComputedScreening"
    />
    <rect v-else :id="id" class="cls-1" :x="x" :y="y" :width="width" :height="height"
          :style="styleComputed"
    />

    <g v-if="item">
      <!-- area-presence -->
      <line
        v-if="item.presence > 0"
        :x1="px1"
        :y1="py1"
        :x2="px2"
        :y2="py2"
        stroke="#4ADFAF"
        stroke-width="5px"
        stroke-linecap="round"
      />
      <g class="data" v-if="show_data">
        <text :x="textXComp" :y="textYComp" font-family="Verdana" font-size="16" fill="black" text-anchor="middle">
          {{ format(tweenedNumber, false) }}
        </text>
      </g>
      <!--      <g v-if="hover" :transform="tooltipTranslate">-->
      <!--        <rect y="-32" width="150" height="32" class="tooltip"></rect>-->
      <!--        <text font-family="Verdana" font-size="16" fill="black">{{ id }}</text>-->
      <!--        <text y="-16" font-family="Verdana" font-size="16" fill="black">{{ timeString }}</text>-->
      <!--      </g>-->
      <g class="data" v-if="show_second_metric">
        <text :x="textXComp + (lane_orientation === 'right'? - 35 : 35)" :y="textYComp" font-family="Verdana"
              font-size="16" fill="black" :text-anchor="lane_orientation === 'right'? 'end': 'start'">
          {{ format(second_measure, true) }}
        </text>
      </g>
    </g>
  </g>
</template>

<script>
import gsap from 'gsap'
import { firebase } from '@/plugins/firebaseInit'

export default {
  name: 'AreaPos',
  props: {
    // item: Object,
    show_positions: Boolean,
    show_data: Boolean,
    minutes_prop: Number,
    id: String,
    lane_id: Number,
    process_step: String,
    area_num: Number,
    area_len: Number,
    position_id: String,
    area_id_string: String,
    x: String,
    y: String,
    width: String,
    height: String,
    points: String,
    px1: Number,
    px2: Number,
    py1: Number,
    py2: Number,
    show_second_metric: Boolean,
    lane_orientation: String
  },
  data () {
    return {
      tweenedNumber: 0,
      hover: false,
      item_fake: {
        bucket_5_minutes: {
          bucket_start_time: firebase.firestore.Timestamp.now(),
          bucket_end_time: firebase.firestore.Timestamp.now(),
          occupancy: 0.75,
          passengers: 15
        },
        last_reading: {
          v18: 22
        }
      }
    }
  },
  methods: {
    format (number, addS) {
      const option = {
        style: (addS || this.process_step === 'screening') ? 'decimal' : 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
      const formatter = new Intl.NumberFormat('nl-NL', option)
      return formatter.format(number) + (addS ? 's' : '')
    }
  },
  computed: {
    // avg_dwell_time () {
    //   return this.last_bucket.total_dwell_time / this.last_bucket.passengers
    // },
    // occupancy () {
    //   return this.last_bucket.total_dwell_time / (parseInt(this.minutes_prop) * 60 * parseInt(this.area_len))
    // },
    measure () {
      if (this.process_step === 'screening') {
        return this.last_bucket.passengers
      } else {
        return this.last_bucket.total_dwell_time /
          (this.minutes_prop * 60 * (this.show_positions ? 1 : this.item.positions.length))
      }
    },
    second_measure () {
      if (this.last_bucket.passengers > 0) {
        return this.last_bucket.total_dwell_time / this.last_bucket.passengers
      } else {
        return 0
      }
    },
    item () {
      var array = []
      if (this.show_positions) {
        array = this.$store.state.positions.filter((item) => {
          return item.id === this.id
        })
      } else {
        array = this.$store.state.areas.filter((item) => {
          return item.id === this.id
        })
      }
      if (array.length === 1) {
        return array[0]
      } else {
        return null
      }
      // return newArray
    },
    // areas_comp () {
    //   return this.$store.state.areas
    // },
    // positions_comp () {
    //   return this.$store.state.positions
    // },
    tooltipTranslate () {
      return `translate(${this.textXComp - (parseInt(this.width) + 150)},${this.textYComp - this.height / 2})`
    },
    timeString () {
      const options = {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit'
      }
      const date = this.last_bucket.bucket_end_time.toDate()
      return date.toLocaleTimeString('nl-NL', options)
    },
    timeStringRT () {
      const options = {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit'
      }
      const date = new Date(this.last_reading.Time)
      return date.toLocaleTimeString('nl-NL', options)
    },
    last_reading () {
      return this.item ? this.item.last_reading : null
    },
    last_bucket () {
      return this.item ? this.item[`bucket_${this.minutes_prop}_minutes`] : null
    },
    styleComputedDivest () {
      if (this.last_bucket) {
        // const val = this.last_bucket.occupancy
        // switch (val) {
        //   case val < 0.5:
        //     return 'fill: #DD392A;'
        //   case val >= 0.5 && val < 0.7:
        //     return 'fill: #DD392A;'
        //   case val >= 0.7 && val < 0.8:
        //     return 'fill: #DD392A;'
        //   case val > 0.8:
        //     return 'fill: #4ADFAF;'
        // }
        if (this.measure < 0.5) {
          return 'fill: #DD392A;'
        } else if (this.measure >= 0.5 && this.measure < 0.7) {
          return 'fill: #DD392A80;'
        } else if (this.measure >= 0.7 && this.measure < 0.8) {
          return 'fill: #4ADFAF80;'
        } else {
          return 'fill: #4ADFAF;'
        }
        // return `fill: rgb(${255 - this.last_bucket.occupancy * 255}, ${100 + this.last_bucket.occupancy * 155} , 0);`
      } else {
        return 'fill: lightgray;'
      }
    },
    styleComputedScreening () {
      if (this.last_bucket) {
        const mult = parseInt(this.minutes_prop) / 5
        // const val = this.last_bucket.occupancy
        // switch (val) {
        //   case val < 0.5:
        //     return 'fill: #DD392A;'
        //   case val >= 0.5 && val < 0.7:
        //     return 'fill: #DD392A;'
        //   case val >= 0.7 && val < 0.8:
        //     return 'fill: #DD392A;'
        //   case val > 0.8:
        //     return 'fill: #4ADFAF;'
        // }
        if (this.last_bucket.passengers < 5 * mult) {
          return 'fill: #DD392A;'
        } else if (this.last_bucket.passengers >= 5 * mult && this.last_bucket.passengers < 10 * mult) {
          return 'fill: #DD392A80;'
        } else if (this.last_bucket.passengers >= 10 * mult && this.last_bucket.passengers < 12 * mult) {
          return 'fill: #4ADFAF80;'
        } else {
          return 'fill: #4ADFAF;'
        }
        // return `fill: rgb(${255 - this.last_bucket.occupancy * 255}, ${100 + this.last_bucket.occupancy * 155} , 0);`
      } else {
        return 'fill: lightgray;'
      }
    },
    styleComputed () {
      if (this.last_bucket) {
        // const val = this.last_bucket.occupancy
        // switch (val) {
        //   case val < 0.5:
        //     return 'fill: #DD392A;'
        //   case val >= 0.5 && val < 0.7:
        //     return 'fill: #DD392A;'
        //   case val >= 0.7 && val < 0.8:
        //     return 'fill: #DD392A;'
        //   case val > 0.8:
        //     return 'fill: #4ADFAF;'
        // }
        if (this.measure < 0.5) {
          return 'fill: #4ADFAF;'
        } else if (this.measure >= 0.5 && this.measure < 0.7) {
          return 'fill: #4ADFAF80;'
        } else if (this.measure >= 0.7 && this.measure < 0.8) {
          return 'fill: #DD392A80;'
        } else {
          return 'fill: #DD392A;'
        }
        // return `fill: rgb(${255 - this.last_bucket.occupancy * 255}, ${100 + this.last_bucket.occupancy * 155} , 0);`
      } else {
        return 'fill: lightgray;'
      }
    },
    textXComp () {
      if (this.process_step !== 'divest') {
        return parseFloat(this.x) + parseFloat(this.width) / 2
      } else {
        const idArray = this.id.split('-')
        if (isNaN(idArray[idArray.length - 1])) { // if is a process step (aggregated)
          return (parseFloat(this.points.split(' ')[4]) - parseFloat(this.points.split(' ')[0])) / 2
        } else { // if is a position (granular)
          return parseFloat(this.points.split(' ')[4]) - ((this.lane_id % 2 === 0) ? -7 : 7) - parseFloat(this.width) / 3 * (this.lane_id % 2 + 1)
          // return parseFloat(this.points.split(' ')[4]) - parseFloat(this.width) / 3 * (this.lane_id % 2 + 1)
        }
      }
    },
    textYComp () {
      if (this.process_step !== 'divest') {
        return parseFloat(this.y) + parseFloat(this.height) / 2 + 7
      } else {
        return parseFloat(this.points.split(' ')[3]) + parseFloat(this.height) / 2 + 7
      }
    }
  },
  mounted () {
    if (this.last_bucket) {
      gsap.to(this.$data, {
        duration: 0.5,
        // tweenedNumber: this.last_bucket.occupancy
        tweenedNumber: this.measure
      })
    }
  },
  watch: {
    // item (newValue) {
    //   if (newValue) {
    //     gsap.to(this.$data, {
    //       duration: 0.5,
    //       tweenedNumber: newValue.v18
    //     })
    //   }
    // }
    // measure (newValue) {
    //   if (newValue) {
    //     gsap.to(this.$data, {
    //       duration: 0.5,
    //       tweenedNumber: this.measure
    //     })
    //   }
    // }
    last_bucket: {
      handler (newValue, oldValue) {
        if (newValue) {
          gsap.to(this.$data, {
            duration: 0.5,
            tweenedNumber: this.measure
          })
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stroke {
  stroke: #3f0d71;
}

.tooltip {
  fill: white;
  stroke: black;
  z-index: inherit;
}
</style>
